import axios from '@/packages/axios/http.js';

const certWeb = '/cert/web/seal';

export class Seal {
    /** 印章制作-预览 */
    static async preview(params) {
        const res = await axios.postMulti(`${certWeb}/preview`, params, { responseType: 'blob' });

        return await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                resolve(e.target.result);
            };
            reader.readAsDataURL(res.data);
        });
    }

    /** 印章图片-获取 */
    static async getSealImage(params) {
        return axios.getImgSrc(`${certWeb}/getimage`, params);
    }

    /** 印章制作-生成 */
    static create(params) {
        return axios.postMulti(`${certWeb}/create`, params);
    }

    /** 印章压缩 透明 */
    static translucent(params) {
        return axios.postMulti(`${certWeb}/translucent`, params);
    }

    static upload(params) {
        return axios.postMulti(`${certWeb}/upload`, params);
    }
}
