import { uuid } from './uuid';

/**
 * 函数创建input从本地选择文件
 * @param {*} opts
 */
export function chooseFile(opts) {
    try {
        let options = {
            accept: '', // 接收文件类型
            capture: '', // 直接调用摄像头 example: "image/*"对应"camera" || "video/*"对应"camcorder"
            multiple: '', // 是否支持选择多文件 "multiple"
        };
        options = Object.assign({}, options, opts);
        const id = uuid();
        let inputVNode = document.createElement('input');
        let ua = navigator.userAgent;
        let isNubia = /.*(NX616J|NX569H).*/.test(ua);
        inputVNode.style.display = 'none';
        inputVNode.id = id;
        inputVNode.type = 'file';
        if (!!options.accept) {
            inputVNode.accept = options.accept;
        }
        if (!!options.capture && !isNubia) {
            inputVNode.capture = options.capture;
        }
        if (!!options.multiple) {
            inputVNode.setAttribute('multiple', options.multiple);
        }

        return new Promise((resolve, reject) => {
            document.body.appendChild(inputVNode);
            let input = document.getElementById(id);
            input.addEventListener(
                'change',
                (e) => {
                    resolve(e);
                    document.body.removeChild(input);
                    // 防止未选择文件导致添加的节点未删除
                    const nodeList = document.querySelectorAll('input[type=file]');
                    if (nodeList.length === 0) {
                        return;
                    }
                    Array.from(nodeList).forEach((node) => {
                        if (node.id && node.id.length === 36) {
                            document.body.removeChild(node);
                        }
                    });
                },
                false
            );
            input.click();
        });
    } catch (error) {
        console.log(error);
    }
}
