import axios from '@/packages/axios/http.js';

const commonUkey = '/common/order';

export class Setting {
    /** 获取客服电话、各个证书和Ukey价格、Qreader是否展示*/
    static getCustomerConfig(params) {
        return axios.postJson(`${commonUkey}/settings`, params);
    }

    /** 获取ca类型 */
    static getCa(params) {
        return axios.postJson(`${commonUkey}/getca`, params);
    }

    /** 获取有定制需求的客户 */
    static getCustomizedCustomer(params) {
        return axios.postJson(`${commonUkey}/customer-id`, params);
    }
}
