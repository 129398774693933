function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 *
 * @param {*} msg
 * @param {*} time s
 */
export async function sleepThenThrowErr(msg, time) {
    await timeout(time * 1000); // ms
    throw new Error(`#sleepWithErr# ${msg}`);
}
// await sleepThenThrowErr();
