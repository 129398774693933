/**
 * 将以 base64 的图片 url 数据转换为 Blob
 * @param urlData
 * 用 url 方式表示的 base64 图片数据
 */

export function convertBase64UrlToBlob(dataUrl) {
    // 去掉 url 的头，并转换为 byte
    const bytes = window.atob(dataUrl.split(',')[1]);
    // 处理异常,将 ascii 码小于 0 的转换为大于 0
    const ab = new Uint8Array(bytes.length);
    for (let i = 0, l = bytes.length; i < l; i++) {
        ab[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
}

// Note: https://blog.csdn.net/yin13037173186/article/details/83302628
export function convertBase64UrlToBlobFinFile(base64, fileName) {
    // base64 -> blob
    function dataURLtoBlob(durl) {
        var arr = durl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        try {
            return new Blob([u8arr], { type: mime });
        } catch (e) {
            // Old browser which can't handle it without making it an byte array (ie10)
            if (e.name == 'InvalidStateError') {
                var byteArray = new Uint8Array(u8arr);
                return new Blob([byteArray.buffer], { type: mime });
            }
        }
    }
    // blob -> file
    function blobToFile(theBlob, theFileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = theFileName;
        return theBlob;
    }

    // 调用
    const blob = dataURLtoBlob(base64);
    const file = blobToFile(blob, fileName);

    return file;
}

/**
 * 图片加载后自适应 设置宽度/高度充满
 *
 * @param {*} event
 */
export function imageAutoAdaptionSize(event) {
    let target = event.target || event.srcElement;
    let scale = (target.parentElement?.offsetWidth ?? 1) / (target.parentElement?.offsetHeight ?? 1);
    let image = target;
    if (image.complete == true) {
        if (image.naturalWidth > image.naturalHeight * scale) {
            image.style.cssText = 'width: 100%;';
        } else {
            image.style.cssText = 'height: 100%;';
        }
    }
}
