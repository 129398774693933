export function getWxAuthURL(path, extraParamObj) {
    const { origin, search } = window.location;

    const param = search.replace(/&(code|amount|status)=.*?((?=&)|$)/g, '');
    const extraParam = extraParamObj
        ? `${param ? '&' : '?'}` +
          Object.entries(extraParamObj)
              .map(([k, v]) => `${k}=${v}`)
              .join('&')
        : '';
    const state = `${window.BASE_PATH}${path}${param}${extraParam}`;

    const newPathName = '/wx/auth';
    const newSearch = `?redirect_uri=${encodeURIComponent(origin)}&state=${encodeURIComponent(state)}`;

    return origin + window.BASE_PATH + newPathName + newSearch;
}
