import axios from '@/packages/axios/http.js';
import { RenewalTypes } from '@/types';

export class Renewal {
    /**
     * 获取全部订单列表
     *
     * @param params
     * @returns
     */
    static getOrderAllList(deviceId: string) {
        return axios.postJson(`/ukey/re/getall`, { deviceId });
    }

    /**
     * 获取分页订单列表
     *
     * @param params
     * @returns
     */
    static getOrderList(params: RenewalTypes.OrderQuery) {
        return axios.postForm(`/ukey/re/page`, params);
    }

    /**
     * 创建订单
     *
     * @param params
     * @returns
     */
    static CreateOrder(params: RenewalTypes.CreateOrderParams) {
        return axios.postJson(`/ukey/re/create`, params);
    }

    /**
     * 获取订单信息
     *
     * @param params
     * @returns
     */
    static getOrderInfo(id: string = '') {
        return axios.postForm(`/ukey/re/getorder`, { id });
    }

    /**
     * 获取订单状态
     *
     * @param params
     * @returns
     */
    static getOrderStatus(params: { id: string; invoiceId: string }) {
        return axios.postForm(`/ukey/re/invoice/status`, params);
    }

    /**
     * 创建发票
     *
     * @param params
     * @returns
     */
    static createInvoice(params: any) {
        return axios.postJson(`/ukey/re/invoice`, params);
    }

    /**
     * 更新发票
     *
     * @param params
     * @returns
     */
    static updateInvoice(params: any) {
        return axios.postJson(`/ukey/re/updateinvoice`, params);
    }

    /**
     * 获取支付链接
     *
     * @param params
     * @returns
     */
    static getPayUrl(params: RenewalTypes.PayCodeParams) {
        return axios.postForm(`/ukey/re/pay`, params);
    }

    /**
     * 获取微信支付二维码
     *
     * @param params
     * @returns
     */
    static getWxPayCode(data: string) {
        return axios.getImgSrc(`/ukey/re/qrcodeimg?data=${data}`);
    }

    /**
     * 获取支付二维码
     *
     * @param params
     * @returns
     */
    static getAliPayCode(params: RenewalTypes.PayCodeParams) {
        return axios.postForm(`/ukey/re/pay`, params);
    }

    /**
     * 下载证书
     *
     * @param params
     * @returns
     */
    static downloadCert(params: { id: string; deviceId: string }) {
        return axios.postJson('/ukey/re/download', params);
    }

    /**
     * 获取证书信息
     *
     * @param params
     * @returns
     */
    static getCertInfo(deviceId: string) {
        return axios.postJson('/ukey/re/info', { deviceId });
    }

    /**
     * 申请证书
     *
     * @param params
     * @returns
     */
    static reapplyCert(params: { id: string; customerId: string; name: string; deviceId: string; p10: string; caType: string }) {
        return axios.postJson('/ukey/re/reapply', params);
    }

    /**
     * 续期写入证书成功后告知后端
     * @param params
     * @returns
     */
    static writeSuccess(id: string) {
        return axios.postJson('/ukey/re/write-success', { id });
    }
}
