import axios from '@/packages/axios/http.js';

const certWeb = '/cert/web/captcha';

export class Captcha {
    static orderEnd(params) {
        return axios.postJson(`${certWeb}/order/end`, params);
    }
    static captchaStart() {
        return axios.get(`${certWeb}/start`);
    }
    static validate(params) {
        return axios.get(`${certWeb}/validate`, params);
    }
    static orderPin(params) {
        return axios.get(`${certWeb}/order/pin`, params);
    }
}
