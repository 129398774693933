import qs from 'qs';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

window.$_keepCN = function (str) {
    return str;
};

// 🌐 初始语言环境
// 常量定义（例BaseConfig、PermissionsDocs、PatternFieldName等）仅执行一次，即绑定的i18n.t()也是一次，因此在初始化i18n实例时设置locale
const initialLocale = getPreferredLocale();

// 初始化i18n实例
export const i18n = new VueI18n({
    locale: initialLocale,
    fallbackLocale: 'zh_CN',
    silentTranslationWarn: true,
    missing: (locale, key, vm, values) => {
        if (locale === 'zh_CN')
            // ⚠️⚠️⚠️
            // 目前模块标识符仅可能是一段，例如 workflow.，contract.
            // 若后续改为多段，则此种解法不适用
            return key.replace(/^(.*?\.)(.*)$/, '$2');
        return key;
    },
    messages: loadLocaleMessages(),
});

/**
 * @description 加载语言资源，中文环境仅加载三方库locales，其他语种还需加载系统对应locales
 * @returns locales
 */
function loadLocaleMessages() {
    let obj: any = {};

    switch (initialLocale) {
        case 'en_US':
            obj = {
                en_US: {
                    ...require('@mitra-design/web/es/locale').en,
                    ...require('@mitra-design/pro-components/es/locale').en,
                    ...require('./lang/en_US.json'),
                },
            };
            break;
        case 'ja':
            obj = {
                ja: {
                    ...require('@mitra-design/web/es/locale').ja,
                    ...require('@mitra-design/pro-components/es/locale').ja,
                    ...require('./lang/ja.json'),
                },
            };
            break;
        default:
            obj = {
                zh_CN: {
                    ...require('@mitra-design/web/es/locale').zh_CN,
                    ...require('@mitra-design/pro-components/es/locale').zh_CN,
                },
            };
    }
    return obj;
}

/**
 * @description 支持链接配置语言标识控制系统语言
 * @returns lang or 空字符串
 */
export function getLangFromUrl() {
    return (qs.parse(location.search, { ignoreQueryPrefix: true }).lang ?? '') as string;
}

/**
 * 从获取localStorage
 */
export function getLangFromLocal() {
    return localStorage.getItem('lang');
}

/**
 * 获取浏览器语言设置
 *
 * @returns {string}
 */
export function getLangFromBrowser() {
    /* ie10及以下的文档模式下 window.navigator.language（不支持） 为undefined，增加一个browserLanguage来取值
    https://caniuse.com/?search=navigator.language
    注意: browserLanguage的规范性并不好，但在ie9 ie10可以用 */
    const navigatorLang = window.navigator.language || (window.navigator as any).browserLanguage;

    return navigatorLang?.includes('zh') ? 'zh_CN' : 'en_US'; // 目前支持中、英，若浏览器/手机系统语言为非中文（例如英语、韩语、法语等）则系统语言默认为英文
}

/**
 * 获取首选语言设置
 * 国际化语言优先级：短信、邮件链接 => 浏览器localStorage => 浏览器/系统语言设置
 *
 * @returns {string} locale
 */
export function getPreferredLocale() {
    const lang = getLangFromUrl() || getLangFromLocal() || getLangFromBrowser();

    return ['zh_CN', 'en_US', 'ja'].includes(lang) ? lang : 'zh_CN'; // 异常值处理
}
