import { getUrlParam } from '@/assets/js/public';
import { getSearchParamPairs } from '@/assets/js/publicMethod';
import forge from 'node-forge';

const service = getUrlParam('service') ?? '';

let hideHeaderFromQueryService = false;
if (service) {
    hideHeaderFromQueryService = getSearchParamPairs(decodeURIComponent(service)).hide_header === 'true' && decodeURIComponent(service).includes(location.origin);
}

export default class Tools {
    /**
     * 是否 qysApp 内嵌
     *
     * @returns { boolean }
     */
    static qysApp: boolean = navigator.userAgent.includes('Qiyuesuoapp') || navigator.userAgent.includes('qiyuesuo');

    static hideHeader: boolean = getUrlParam('hide_header') === 'true' || hideHeaderFromQueryService;

    static thirdAppHideHeader: boolean = getUrlParam('hide_header') === 'true';

    static ua = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    /**
     * 手机号正则验证
     * @method getMobileReg
     *
     * @param { string } value
     * @returns { boolean }
     */
    static getMobileReg(value: string): boolean {
        return !/^1[0123456789]\d{9}$/.test(value);
    }

    static aes_encrypt(param: string): string {
        const iv = '5f6db7ec8325a5e4';
        const key = '5f6db7ec8325a5e4';
        const c = forge.cipher.createCipher('AES-ECB', key);
        c.start({ iv });
        c.update(forge.util.createBuffer(param, 'utf8'));
        c.finish();
        return forge.util.encode64(c.output.getBytes());
    }

    static aes_decrypt(param: string) {
        const key = '5f6db7ec8325a5e4';
        const iv = '5f6db7ec8325a5e4';
        const decipher = forge.cipher.createDecipher('AES-ECB', key);
        decipher.start({ iv });
        decipher.update(forge.util.createBuffer(forge.util.decode64(param)));
        decipher.finish();
        return decipher.output.toString();
    }

    static getQueryString(name: string) {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        const r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        }
        return null;
    }
}
