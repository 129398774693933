/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData
 * 用url方式表示的base64图片数据
 */
export function isEmpty(value) {
    return (
        !value ||
        (Array.isArray(value) && value.length === 0) ||
        (Object.prototype.toString.call(value) === Object.prototype.toString.call({}) &&
            Object.keys(value).length === 0)
    );
}

export function includesEmpty(obj) {
    return Object.values(obj).some((v) => isEmpty(v));
}
