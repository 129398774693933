import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import { Apis } from './api/index';
import { getPreferredLocale } from '@/i18n';
import { getUrlParam } from './assets/js/public';

Vue.use(Vuex);
const UA = window.navigator.userAgent;

const state = {
    // 二维码进入的私有云订单
    isPrivateQRCode: undefined || localStorage.getItem('isPrivateQRCode'),
    showQreader: undefined,
    isWXB: /MicroMessenger/i.test(UA), // short for 'is weixin browser' 当前是否是微信浏览器
    isAliB: /AlipayClient/i.test(UA),
    isIE: /MSIE|Trident\//.test(UA),
    isSafari: !/Edg/.test(UA) && !/Chrome/.test(UA) && /Safari/.test(UA), // More on https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers#answer-26358856
    /** 登录手机号 */
    mobile: '',
    /** 扫码进来的客户id */
    customerId: '',
    order: {},
    returnUrl: '',
    /** 证书单价 */
    certUnitPrice: {
        gdca_24: 200,
        gdca_12: 100,
        cfca_24: 200,
        cfca_12: 100,
        sheca_24: 200,
        sheca_12: 100,
        cwca_24: 200,
        cwca_12: 100,
        ukey: 100,
    },
    customerContact: '', // 客服联系方式
    fileKey: '',
    transparency: 30,
    customizedCustomer: {},
    certIndex: undefined,
    image: '',
    isNeedSeal: '',
    /** h5 签名面板上传图片头部 */
    isNewAppVersion: UA?.includes('Qiyuesuoapp') || UA?.includes('qiyuesuo'),
    /** 后台配置的证书可申请类型 */
    certTypeLimit: {},
    moduleSource: '',
    socketUKeyInfo: null,
    hKey: 1, // query 中的弹窗标识符
    isDialogActive: false, // 是否打开了全屏类弹窗
    /**
     * ### 导航栏组件数据
     *
     * 组件配置, 提供给导航头组件(单例)使用
     */
    navbarConfig: {
        title: 'Ukey与移动签章申请',
        backBtn: true,
    },
};

const getters = {
    mobile(state) {
        return state.mobile || localStorage.getItem('mobile');
    },
    customerId(state) {
        return state.customerId || localStorage.getItem('customerId');
    },
    order(state) {
        return state.order;
    },
    returnUrl(state) {
        return state.returnUrl || sessionStorage.getItem('returnUrl');
    },
    customerContact(state) {
        if (state.moduleSource === 'renewal') {
            return '400-888-9792';
        }
        return state.customerContact;
    },
    certUnitPrice(state) {
        return state.certUnitPrice;
    },
    certTypePermit(state) {
        const certTypeLimit = state.certTypeLimit;
        const certTypePermit = Object.keys(certTypeLimit).filter((x) => certTypeLimit[x]);
        return certTypePermit;
    },
    orderConfig(state) {
        const { source, extension: privateOrderConfig } = state.order;
        const isPrivateOrder = source === 'PRIVATE';
        const allPayWays = ['WX', 'ALI', 'OFFLINE'];
        return {
            hideMyOrder: isPrivateOrder,
            needReAuth: privateOrderConfig?.reAuth ?? false,
            needPay: privateOrderConfig?.pay ?? true,
            supportedPayWay: privateOrderConfig?.payType
                ? _.intersection(allPayWays, JSON.parse(privateOrderConfig.payType))
                : allPayWays,
            makeType: privateOrderConfig?.makeType ?? 'INDEPENDENT',
            supportReApply: !isPrivateOrder,
            supportInvocie: isPrivateOrder ? privateOrderConfig.pay && privateOrderConfig.invoice : true,
            showQreader: isPrivateOrder ? state.showQreader : true,
            isUkeyNeedSeal: state.isNeedSeal || 'CUSTOM',
            supportModifyOrder: isPrivateOrder ? !privateOrderConfig?.certInfo : true,
            returnUrl: privateOrderConfig?.returnUrl ?? '',
            hasPrivateCertInfo: privateOrderConfig?.certInfo ?? false, //私有云那边是否提交证书信息
        };
    },
    customizedCustomerName(state) {
        return new Map(_.toPairs(_.invert(state.customizedCustomer))).get(state.order.customerId);
    },
    isNewAppVersion(state) {
        return state.isNewAppVersion;
    },
    moduleSource(state) {
        return state.moduleSource;
    },
    socketUKeyInfo(state) {
        return state.socketUKeyInfo;
    },
    hKey: (state) => state.hKey,
    isDialogActive: (state) => state.isDialogActive,
    /**仅H5 */
    isHideHeader: () => getUrlParam('hide_header') === 'true',
};

const mutations = {
    setMobile(state, value) {
        state.mobile = value;
        localStorage.setItem('mobile', value);
    },
    removeMobile(state, value) {
        state.mobile = '';
        localStorage.removeItem('mobile');
    },
    setCustomerId(state, value) {
        state.customerId = value;
        localStorage.setItem('customerId', value);
    },
    removeCustomerId(state, value) {
        state.customerId = '';
        localStorage.removeItem('customerId');
    },
    setOrder(state, value) {
        state.order = value;
    },
    setCustomerContact(state, value) {
        state.customerContact = value;
    },
    setCertTypeLimit(state, value) {
        state.certTypeLimit = value;
    },
    setCertUnitPrice(state, value) {
        state.certUnitPrice = value;
    },
    setReturnUrl(state, value) {
        state.returnUrl = value;
        sessionStorage.setItem('returnUrl', value);
    },
    setQreader(state, value) {
        state.showQreader = JSON.parse(value);
    },
    removeReturnUrl(state, value) {
        state.returnUrl = '';
        sessionStorage.removeItem('returnUrl');
    },
    saveFileKey(state, value) {
        state.fileKey = value;
    },
    saveTransparency(state, value) {
        state.transparency = value;
    },
    setCustomizedCustomer(state, value) {
        state.customizedCustomer = value;
    },
    saveCertIndex(state, value) {
        state.certIndex = value;
    },
    saveImage(state, value) {
        state.image = value;
    },
    setNeedSeal(state, value) {
        state.isNeedSeal = value;
    },
    setPrivateQRCode(state, value) {
        state.isPrivateQRCode = value;
        localStorage.setItem('isPrivateQRCode', value);
    },
    setModuleSource(state, value) {
        state.moduleSource = value;
    },
    setSocketUKeyInfo(state, value) {
        state.socketUKeyInfo = value;
    },
    set_navbarConfig(state, payload) {
        state.navbarConfig = payload;
    },
    set_dialogActive(state, payload) {
        state.isDialogActive = payload
    }
};

const actions = {
    async initOrder({ dispatch, commit }, id) {
        return new Promise(async (resolve, reject) => {
            if (!id) {
                commit('setOrder', {});
                return;
            }
            let { code, message, result } = await Apis.order.getOrder({ id });
            if (code !== 0) {
                commit('setOrder', {});
                reject({ code, message });
                return;
            }
            if (result.fee) {
                result.fee = result.fee / 100;
            }
            if (result.customerId) {
                dispatch('getCustomerConfig', result);
            }
            dispatch('getCustomizedCustomer');
            commit('setOrder', result);
            commit('setReturnUrl', result?.extension?.returnUrl ?? '');
            resolve(result);
        });
    },

    /**
     *  初始化价格
     *  初始化客服联系方式
     *  初始化QREADER是否展示
     */
    getCustomerConfig({ commit }, data) {
        Apis.setting
            .getCustomerConfig({ customerId: data.customerId, id: data.id, modify: data.modify })
            .then(({ result, code }) => {
                if (code === 0) {
                    /** 处理后端单价除以100 */
                    if (result.PRICE) {
                        for (const [key, value] of Object.entries(result.PRICE)) {
                            result.PRICE[key] = Number(value) / 100;
                        }
                        commit('setCertUnitPrice', result.PRICE);
                    }
                    if (result.CAN_BUY_CERT_MEDIUM) commit('setCertTypeLimit', result.CAN_BUY_CERT_MEDIUM);
                    if (result.CONTACT) commit('setCustomerContact', result.CONTACT.contact);
                    if (result.QREADER) commit('setQreader', result.QREADER.qReader);
                    if (result.UKEY_NEED_SEAL) commit('setNeedSeal', result.UKEY_NEED_SEAL.ukeyNeedSeal);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getCustomizedCustomer({ commit }) {
        Apis.setting
            .getCustomizedCustomer()
            .then((data) => {
                commit('setCustomizedCustomer', data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    logOut({ state, commit, getters }) {
        commit('removeMobile');
        commit('removeCustomerId');
        setTimeout(() => {
            const loginUrl =
                window.BASE_PATH +
                '/basic' +
                '?service=' +
                encodeURIComponent(location.href) +
                `&lang=${getPreferredLocale()}`;
            const linkExpiredUrl = window.BASE_PATH + `/link-expired?lang=${getPreferredLocale()}`;

            if (location.search.includes('bizId')) {
                location.href = getters.returnUrl || linkExpiredUrl;
                commit('removeReturnUrl');
            } else {
                location.href = loginUrl;
            }
        }, 1000);
    },

    getModuleSource({ commit }, data) {
        commit('setModuleSource', data);
    },

    getSocketUKeyInfo({ commit }, data) {
        commit('setSocketUKeyInfo', data);
    },
    // 同步导航栏组件配置 -> app-navbar
    async update_navbarConfig(
        store,
        payload
    ) {
        const { navbarConfig } = store.state;
        const { config: newConfig, route } = payload;

        // 更新导航头组件标题
        store.commit('set_navbarConfig', {
            ...navbarConfig,
            border: true,
            ...newConfig,
        });

        // FIXME: 挪到其他地方处理
        const { title, backBtn } = newConfig;
        const hideHeaderFromQuery = route?.query.hide_header === 'true';
        // 下面两个判断标志场景较少, 目前是 /login 路由和 带 ?viewToken=xx 的路由
        const hideHeaderFromQueryService = (route?.query.service || '').includes(encodeURIComponent('hide_header=true'));
        const enableHideHeaderMode = hideHeaderFromQuery || hideHeaderFromQueryService;

        /**
         * 未开启隐藏配置, 标题与旧版保持一样, 每个页面标题统一
        */
        // if (route && !enableHideHeaderMode) {
        //     await Navbar.helper.setPageTitle(store.state.systemName, true);
        //     return;
        // }

        // 更新 APP 标题
        if (title) {
            await Navbar.helper.setPageTitle(title);
        }
        // APP - 自定义返回
        if (route && enableHideHeaderMode) {

            const { meta = {}, path } = route;
            const params = {
                id: meta.navbar
                    ? meta.__id
                    : `NAVBAR_NORMAL_${path}`,
                title,
                from: 'NAVBAR_ROUTE_GUARD',
                custom: false,
                // 使用具名函数, 调试可显示函数名
                backHandler: function routeDefaultBackHandler() {
                    // 默认路由回退逻辑
                    window.history.go(-1);
                }
            };
            Navbar.helper.configNavigation(params, false);
        }

        if (backBtn) {
            // TODO:
            // WEB - 隐藏返回
            // APP - 隐藏返回, 需要客户端支持
        }
    },
    updateDialogActive({ commit }, data) {
        commit('set_dialogActive', data)
    }
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
});
