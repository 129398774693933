export let trim = (string) => {
    if (typeof string === 'string') {
        return string.trim();
    } else if (typeof string === 'object') {
        const trimedStrObj = {};
        Object.entries(string).forEach(([k, v]) => {
            if (typeof v === 'string') {
                trimedStrObj[k] = v.trim();
            } else {
                trimedStrObj[k] = v;
            }
        });
        return trimedStrObj;
    }

    return string;
};
