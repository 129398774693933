import { i18n } from '@/i18n';
import axios from 'axios';
import qs from 'qs';
import store from '@/store';
import { Toast } from 'vant';
import { Message } from 'element-ui';
import forge from 'node-forge';
import ToolsService from '@/service/tools';
import { getPreferredLocale } from '@/i18n/index';

let options = {
    configApi: '',
    responseCodeFn: {},
    headers: {},
};

axios.interceptors.request.use(
    (config) => {
        config.headers['I18N-Language'] = getPreferredLocale();
        config.url = window.BASE_PATH + config.url + `${config.url.includes('?') ? '&' : '?'}cb=${new Date().getTime()}`;
        config.withCredentials = true;

        // 续期模块
        if (Object.is(store.getters.moduleSource, 'renewal')) {
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            config.headers['access-token'] = ToolsService.aes_encrypt(`ukeyrenew${new Date().getTime()}`);
        }
        return config;
    },
    (err) => {
        throw new Error(err);
    }
);

axios.interceptors.response.use(
    (response) => {
        const { data } = response;
        if (data.code !== 0) {
            switch (data.code) {
                case 101: {
                    // 权限过期
                    window.BROWSERTYPE === 'pc' ? Message.warning(i18n.t('_packages.会话失效，请重新验证！')) : Toast(i18n.t('_packages.会话失效，请重新验证！'));
                    store.dispatch('logOut');
                    break;
                }
                case 102: {
                    window.BROWSERTYPE === 'pc' ? Message.warning(i18n.t('_packages.服务器异常')) : Toast(i18n.t('_packages.服务器异常'));
                    break;
                }
                case 103: {
                    // 存在越权
                    window.BROWSERTYPE === 'pc' ? Message.warning(data.message) : Toast(data.message);
                    store.dispatch('logOut');
                    break;
                }
                default:
                    break;
            }
        }
        return response;
    },
    (err) => {
        const { status, data } = err.response;
        if (status !== 200) {
            window.BROWSERTYPE === 'pc' ? Message.warning(data.message || data) : Toast(data.message || data);
        }

        throw new Error(err);
    }
);

async function get(url, data = null, config = {}) {
    let res = await axios({
        method: 'GET',
        url,
        params: data,
        headers: Object.assign(config.headers || {}),
    });
    return res.data;
}

async function getImgSrc(url, data = null, config = {}) {
    let res = await axios({
        method: 'GET',
        url,
        params: data,
        headers: Object.assign(config.headers || {}),
        responseType: 'blob',
    });
    //! 兼容华为V20浏览器 长按图片blob url下载不了
    return await new Promise((success) => {
        let reader = new FileReader();
        reader.onload = function (e) {
            success(e.target.result);
        };
        reader.readAsDataURL(res.data);
    });
}

async function getFileBlob(url, data = null, config = {}) {
    let res = await axios({
        method: 'GET',
        url,
        params: data,
        headers: Object.assign(config.headers || {}),
        responseType: 'blob',
    });
    return res;
}

async function post(url, data, config = {}, type) {
    let contentType, body, responseType;

    switch (type) {
        case 'form':
            contentType = 'application/x-www-form-urlencoded';
            body = qs.stringify(data);
            break;
        case 'multi':
            contentType = 'multipart/form-data';
            responseType = config.responseType;
            body = new FormData();
            Object.keys(data).forEach((key) => {
                if (Array.isArray(data[key])) {
                    data[key].forEach((value) => {
                        body.append(key, value);
                    });
                } else {
                    let isBlob = Object.prototype.toString.call(data[key]).slice(8, -1) == 'Blob';
                    if (isBlob) {
                        body.append(key, data[key], data[key].name);
                    } else {
                        body.append(key, data[key]);
                    }
                }
            });
            break;
        case 'json':
            contentType = 'application/json';
            body = data;
            break;
    }
    let res = await axios({
        method: 'POST',
        url,
        headers: Object.assign(config.headers || {}, {
            'Content-Type': contentType,
        }),
        data: body,
        cancelToken: config.cancelToken || null,
        onUploadProgress: config.onProgress,
        responseType: config.responseType,
    });
    if (config.responseType === 'blob') {
        return res;
    }
    return res.data;
}

export default {
    get,
    getImgSrc,
    getFileBlob,
    async postForm(url, data, config = {}) {
        return post(url, data, config, 'form');
    },
    async postMulti(url, data, config = {}) {
        return post(url, data, config, 'multi');
    },
    async postJson(url, data, config = {}) {
        return post(url, data, config, 'json');
    },
    setOptions(opts) {
        Object.entries(opts).forEach(([key, value]) => {
            if (value instanceof Function || typeof value !== 'object') {
                options[key] = value;
                return;
            }
            options[key] = Object.assign(options[key], value);
        });
    },
};
