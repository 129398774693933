/**
 * 阻塞
 * @param {*} time 等待时间
 */
export function sleep(time) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
}
