import download from 'downloadjs';
import { Message } from 'element-ui';
import { Toast } from 'vant';
import axios from 'axios';
import $qHttp from '@/packages/axios/http.js';

/**
 * 通过ajax获取文件流将文件下载至客户端
 *
 * @name downloadFile
 * @param {string} url 请求路径
 * @param {any} params 请求参数
 * @param {string} method 请求类型，默认GET，可缺省
 */
export async function downloadFile(url, params, method = 'GET') {
    const { headers, data } =
        method === 'GET'
            ? await $qHttp.getFileBlob(url, params)
            : await axios.post(url, params, {
                  responseType: 'blob',
              });

    const reader = new FileReader();

    reader.readAsText(data, 'utf-8');
    reader.onload = () => {
        if (data.type === 'application/json') {
            const { code, message } = JSON.parse(reader.result);
            if (code) {
                window.BROWSERTYPE === 'pc' ? Message.warning(message) : Toast(message);
            }
            return;
        }

        const fileName = headers['content-disposition'].match(/filename=(.+)/i)?.[1] || '';

        download(data, decodeURIComponent(fileName), 'application/octet-stream');
    };
}
