import Bowser from 'bowser';

// 获取URL参数
export function getUrlParam(paramName) {
    const url = document.location.toString();
    const [path, paramsStr] = url.split('?');

    if (!paramsStr) {
        return '';
    }

    const paramPairList = paramsStr.split('&').map(str => {
        const [k, v = ''] = str.split('=');
        return [k, v];
    });
    const pair = paramPairList.find(([k, v]) => k == paramName) || [paramName, ''];

    return pair[1];
}

// 时间格式化
export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
        }
    }
    return fmt;
}

export function addClass(el, cls) {
    if (!el) return;
    let curClass = el.className;
    let classes = (cls || '').split(' ');

    for (let i = 0, len = classes.length; i < len; i++) {
        let clsName = classes[i];
        if (!clsName) continue;

        if (el.classList) {
            el.classList.add(clsName);
        } else {
            if (!hasClass(el, clsName)) {
                curClass += ' ' + clsName;
            }
        }
    }
    if (!el.classList) {
        el.className = curClass;
    }
}

export function removeClass(el, cls) {
    if (!el || !cls) return;
    let curClass = ' ' + el.className + ' ';
    let classes = cls.split(' ');

    for (let i = 0, len = classes.length; i < len; i++) {
        let clsName = classes[i];
        if (!clsName) continue;

        if (el.classList) {
            el.classList.remove(clsName);
        } else {
            if (hasClass(el, clsName)) {
                curClass = curClass.replace(' ' + clsName + ' ', ' ');
            }
        }
    }
    if (!el.classList) {
        el.className = trim(curClass);
    }
}

export function getDeviceType() {
    const browser = Bowser.getParser(navigator.userAgent);
    const osName = browser.getOSName();
    let type = 'android'; // 终端默认为安卓

    const isAndroid = osName === 'Android';
    const isiOS = osName === 'iOS';

    if (isAndroid) {
        type = 'android';
    } else if (isiOS) {
        type = 'ios';
    }
    return type;
}

/*
 * api doc (keyword: clientType): https://wiki.qiyuesuo.me/pages/viewpage.action?pageId=11439333
 * weapp doc (keyword: 环境): https://developers.weixin.qq.com/miniprogram/dev/component/web-view.html
 */
export function getBrowserType() {
    let type = 'mobilebrowser';
    const UA = window.navigator.userAgent;
    if (/dingtalk/i.test(UA)) {
        type = 'dteapp'; // '钉钉e应用'
    } else if (/miniProgram/i.test(UA)) {
        type = 'weapp'; // '微信小程序'
    } else if (/MicroMessenger/i.test(UA)) {
        type = window.__wxjs_environment === 'miniprogram' ? 'weapp' : 'weixin'; // '微信小程序'或'微信'
    }
    return type;
}

/**
 * 将数字或字符串格式化为两位小数的金额格式
 * @param money
 * @returns {string}
 */
export function formatMoney(money) {
    let moneyStr = money + '';
    let int = moneyStr.split('.')[0];
    if (int === undefined || int === 'undefined' || int === null || int === '') {
        int = 0;
    }
    let decimal = moneyStr.split('.')[1] || '';
    if (decimal.length) {
        return int + '.' + (decimal + '00').substr(0, 2);
    } else {
        return int + '.00';
    }
}

export function validateContract(value, type) {
    let regs = {
        ZH: /^(\+86|86)?1[0-9]{10}$/,
        HK: /^(5|6|9)\d{7}$/,
        TW: /^0?9\d{8}$/,
        email: /^[\.A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
    };
    let reg = regs[type];

    if (!value) {
        return false;
    } else {
        if (!reg.test(value)) {
            return false;
        }
        return true;
    }
}

/**
 * url参数解析
 * @example String "?id=123456&a=b&c="
 * @param url
 * @return Object {id:"123456",a:"b"}
 */
export function urlParse(url) {
    if (!url) {
        url = window.location.toString();
    } else {
        url = decodeURIComponent(url);
    }
    let result = {};
    const reg = /[?&][^?&]+=[^?&]+/g;
    let arr = url.match(reg); // return ["?id=123456","&a=b"]
    if (arr) {
        arr.forEach(item => {
            let tempArr = item.substring(1).split('=');
            let key = tempArr[0];
            let val = tempArr[1] || '';
            result[key] = val;
        })
    }
    return result;
}

/**
 * 获取带区号的联系方式
 * @param areaCode 区号
 * @param content 联系方式
 * @returns {string}
 */
export function getContactWithCode(areaCode, content) {
    let result;
    if (!areaCode || areaCode === '86') {
        result = content;
    } else {
        result = `${areaCode} ${content}`;
    }
    return result;
}

/**
 * 根据带区号的联系方式获取区号和联系方式
 * @param contact 带区号的联系方式
 * @returns {{contactContent: *, contactCode: (*|string)}}
 */
export function getContactCodeAndContent(contact) {
    const phoneRegLoose = /^(?:\+?(86|852|886)\s?)?(.*)$/;
    const contactCode = contact.match(phoneRegLoose)[1] || '';
    const contactContent = contact.match(phoneRegLoose)[2];
    return {
        contactCode,
        contactContent
    };
}

/**
 * 获取手机区号数据
 * @param {*} contact
 */
export function getAreaCodeObj(contact) {
    const codeList = [
        { label: '中国', areaAbbr: 'ZH', code: '+86', value: '' },
        { label: '中国香港', areaAbbr: 'HK', code: '+852', value: '852' },
        { label: '中国台湾', areaAbbr: 'TW', code: '+886', value: '886' },
    ];
    const phoneRegLoose = /^(?:\+?(86|852|886)\s?)?(.*)$/;
    const contactCode = contact.match(phoneRegLoose)[1] || '86';
    if (contactCode === '' || contactCode === '86') {
        return { label: '中国', areaAbbr: 'ZH', code: '+86', value: '' };
    }
    return codeList.find(item => item.value === contactCode);
}

/**
 * 序列化url
 * @example serializeUrl({a: 1, b: 2})
 * @return {window.location.href}?a=1&b=2
 * @param {*} query：序列化参数对象 coverSameParam：若当前url存在相同参数，是否覆盖，默认不覆盖
 */
export function serializeUrl(query = {}, coverSameParam = false, queryUrl = '') {
    const url = queryUrl || decodeURIComponent(window.location.href);
    const queryReg = /\?{1}[A-Za-z0-9\u4e00-\u9fa5_\.\+\-\/\?=&:%]*/g;
    let queryStr = '', queryArr = [];
    if (!!url.match(queryReg)) {
        let arr = url.match(queryReg)[0].split('?')[1];
        queryArr = arr.split('&');
    }
    Object.keys(query).forEach(q => {
        let index = 0;
        let queryItem = queryArr.find((item, i) => {
            let paramKey = item.split('=')[0];
            index = i;
            return paramKey === q;
        })
        // 当前链接已存在相同参数，根据配置是否删除
        if (!!queryItem && coverSameParam) {
            queryArr.splice(index, 1);
        }
        if (!queryItem || coverSameParam) {
            queryArr.push(`${q}=${query[q]}`);
        }
    })
    queryStr = `?${queryArr.join('&')}`;
    return `${url.split('?')[0]}${queryStr}`;
}

export function isWeChatAndIos() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeChat = /MicroMessenger/i.test(ua);
    const isIos = /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua);
    return isWeChat && isIos;
}

/**
 * 解决ios 微信浏览器 键盘收起页面不回弹的问题
 */
export function fixWeChatInputPageHangUp() {
    let windowScrollTimer;
    const isWXAndIos = isWeChatAndIos();
    if (isWXAndIos) {
        document.body.addEventListener('focusin', () => {
            clearTimeout(windowScrollTimer);
        });
        document.body.addEventListener('focusout', () => {
            clearTimeout(windowScrollTimer);
            windowScrollTimer = window.setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 200);
        });
    }
}
