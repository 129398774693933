import { i18n } from '@/i18n';
import EXIF from 'exif-js';
/**
 * 图片压缩
 * @param file 图片原文件
 *
 * @param maxDefaultSize 压缩后的最大值限制，默认10M
 * @param quality 压缩后的质量,取值范围:(0, 1], 默认值:1
 * @param type 压缩后的文件后缀名, 默认值:'jpeg'
 * @returns {Promise<any>}
 */
export default function compressImg(file = null, outputType = 'base64', maxDefaultSize = 10, quality = 1, type = 'png') {
    let Orientation = '';
    EXIF.getData(file, function () {
        EXIF.getAllTags(this);
        Orientation = EXIF.getTag(this, 'Orientation');
    });
    const imgQuality = quality > 1 ? 1 : quality <= 0 ? 0.1 : quality; // imgQuality取值范围:(0, 1]
    const reader = new FileReader();
    const img = new Image();
    // 图片转base64后的大小
    let imgSize = 0;
    // 选择的文件是图片
    if (file.type.indexOf('image') === 0) {
        reader.readAsDataURL(file);
    }
    // 文件base64化，以便获知图片原始尺寸
    reader.onload = function (e) {
        imgSize = e.total;
        img.src = e.target.result;
    };
    // 创建缩放图片所需的canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    // 返回promise对象
    return new Promise((resolve, reject) => {
        // base64地址图片加载完毕后
        img.onload = (e) => {
            // 图片原始尺寸
            const originWidth = img.width;
            const originHeight = img.height;
            // 最大尺寸限制和最大图片占用空间限制
            const maxWidth = 1200;
            const maxHeight = 900;
            const maxSize = 2 * 1024 * 1024; // 2M
            // 目标尺寸
            let targetWidth = originWidth;
            let targetHeight = originHeight;
            // 图片尺寸超过500*500最大尺寸的限制
            if (imgSize > maxSize || originWidth > maxWidth || originHeight > maxHeight) {
                // 图片原始宽高比例大于最大宽高比例，按照宽度限定尺寸
                if (originWidth / originHeight > maxWidth / maxHeight) {
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                } else {
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                    targetHeight = maxHeight;
                }
            }

            // 使用canvas对图片进行缩放压缩操作
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            // 清除画布
            context.clearRect(0, 0, targetWidth, targetHeight);
            // 图片压缩
            context.drawImage(img, 0, 0, targetWidth, targetHeight);
            if (Orientation != '' && Orientation != 1 && Orientation != undefined) {
                const width = targetWidth;
                const height = targetHeight;
                switch (Orientation) {
                    case 6: // 需要顺时针90度旋转
                        canvas.width = height;
                        canvas.height = width;
                        context.rotate((90 * Math.PI) / 180);
                        context.drawImage(img, 0, 0, width, -height);
                        break;
                    case 8: // 需要逆时针90度旋转
                        canvas.width = height;
                        canvas.height = width;
                        context.rotate((-90 * Math.PI) / 180);
                        context.drawImage(img, 0, 0, -width, height);
                        break;
                    case 3: // 需要180度旋转
                        context.rotate((180 * Math.PI) / 180);
                        context.drawImage(img, 0, 0, -width, -height);
                        break;
                }
            }

            if (outputType === 'base64') {
                // canvas转为base64上传
                const targetType = type ? `image/${type}` : file.type || 'image/jpeg';
                const base64 = canvas.toDataURL(targetType, imgQuality);
                const strContent = base64.split('base64,')[1].replace(/=/g, '');
                const contentLen = strContent.length;
                const base64Size = parseInt(contentLen - (contentLen / 8) * 2);
                // 压缩后还大于压缩后最大值限制
                if (base64Size > maxDefaultSize * 1024 * 1024) {
                    const errMsg = `${i18n.t('_tools.图片超过')}${maxDefaultSize}M,${i18n.t('_tools.请重新选择')}`;
                    reject(errMsg);
                } else {
                    resolve(base64);
                }
            } else if (outputType === 'blob') {
                // canvas转为blob上传
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, `image/${type}` || file.type || 'image/jpeg');
            }
        };
        img.onerror = () => {
            const errMsg = i18n.t('_tools.图片加载失败');
            reject(errMsg);
        };
    });
}
// 部分手机没有toBlob
if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value(callback, type, quality) {
            var binStr = atob(this.toDataURL(type, quality).split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], { type: type || 'image/png' }));
        },
    });
}
