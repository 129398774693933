import axios from '@/packages/axios/http.js';
import { getPreferredLocale } from '@/i18n/index';
import { isMacOS } from '@/tools/common';
const commonUkey = '/common/order';
export class Order {
    /** 获取章面图片 */
    static getseal(params) {
        return axios.get(`${commonUkey}/seal/get`, params);
    }

    /** 保存章面信息 */
    static saveSeal(params, config) {
        return axios.postJson(`${commonUkey}/seal/save`, params, config);
    }

    /** 修改账面信息 */
    static updateSeal(params, config) {
        return axios.postJson(`${commonUkey}/seal/update`, params, config);
    }

    /** 删除章面信息 */
    static deleteSeal(params) {
        return axios.postJson(`${commonUkey}/seal/delete`, params);
    }

    /** 校验订单与手机号是否匹配 */
    static checkOrderByPhone(params) {
        return axios.postJson(`${commonUkey}/check-order`, params);
    }

    /** 根据手机号获取所有订单 */
    static getOrderList(params) {
        return axios.postJson(`${commonUkey}/list`, params);
    }

    /** 关闭订单 */
    static closeOrder(params) {
        return axios.postJson(`${commonUkey}/close-orders`, params);
    }

    /** 订单基本信息提交 */
    static submitOrder(params) {
        return axios.postJson(`${commonUkey}/commit`, params);
    }

    /** 获取认证链接 */
    static getAuthUrl(params) {
        const lang = {
            0: {
                en_US: 'en_US',
                ja: 'ja_JP',
                zh_CN: 'zh_CN',
            },
            1: {
                en_US: 'en',
                ja: 'ja',
                zh_CN: 'cn',
            },
        };
        return axios.postJson(`${commonUkey}/get-url`, {
            ...params,
            language: lang[params.companyType][getPreferredLocale()],
        });
    }

    /** 获取认证状态信息 */
    static getAuthStatus(params) {
        return axios.postJson(`${commonUkey}/auth-status`, params);
    }

    /** 订单信息补充提交（证书、ukey数量） */
    static submitExtraOrder(params) {
        return axios.postJson(`${commonUkey}/basic`, params);
    }

    /** 订单信息修改（证书、ukey数量） */
    static updateExtraOrder(params) {
        return axios.postJson(`${commonUkey}/update-order`, params);
    }

    /** 获取订单信息 */
    static getOrder(params) {
        return axios.postJson(`${commonUkey}/get`, params);
    }

    /** 修改收件信息 */
    static recipientUpdate(params) {
        return axios.postJson(`${commonUkey}/update-recipientInfo`, params);
    }

    /** 确认收货 */
    static confirmReceipt(params) {
        return axios.postJson(`${commonUkey}/recipient`, params);
    }

    /** 验证对公账户打款 */
    static validatePayOffline(params) {
        return axios.postJson(`${commonUkey}/pay/offline`, params);
    }

    /** 订单支付获取codeURL */
    static orderPay(params) {
        return axios.postJson(`${commonUkey}/pay`, params);
    }

    /** 获取微信二维码图片 */
    static getWxQrcodeImg(params) {
        return axios.getImgSrc(`${commonUkey}/qrcode`, params);
    }

    /** 获取微信支付二维码 */
    static getQrcode(params) {
        return axios.postJson(`${commonUkey}/qrcode`, params);
    }

    /** 获取订单支付信息 */
    static getOrderPayInfo(params) {
        return axios.postJson(`${commonUkey}/pay/info`, params);
    }

    /** 下载文件查看签章效果 */
    static downloadFileBySeal(params) {
        return axios.postJson(`${commonUkey}/download-file`, params, { responseType: 'blob' });
    }

    /** 单体章申请云端证书 */
    static applyCert(params) {
        return axios.postJson(`${commonUkey}/apply-cert`, params);
    }

    /** 单体章下载云端证书 */
    static downloadCert(params) {
        return axios.postJson(`${commonUkey}/download-cert`, params, { responseType: 'blob' });
    }

    /** 创建开票信息 */
    static createInvoice(params) {
        return axios.postJson(`${commonUkey}/create-invoice`, params);
    }

    /** 获取开票信息 */
    static getInvoice(params) {
        return axios.postJson(`${commonUkey}/invoice-info`, params);
    }

    /** 修改开票信息 */
    static updateInvoice(params) {
        return axios.postJson(`${commonUkey}/update-invoice`, params);
    }

    /** 清空ukey信息 */
    static clearUkey(params) {
        return axios.postJson(`${commonUkey}/clear-medium`, params);
    }

    /** 获取驱动最新版本 */
    static async getLatestDriverVersion() {
        let result;
        return new Promise(async (resolve, reject) => {
            const address = isMacOS() ? `https://dl.qiyuesuo.com/Ukey/Version_macos.txt?cb=${new Date().getTime()}` : `https://dl.qiyuesuo.com/Ukey/Version.txt?cb=${new Date().getTime()}`;
            await fetch(address)
                .then((response) => {
                    return response.text();
                })
                .then(async (data) => {
                    result = data.substring(data.indexOf('version:') + 8, data.length - 1);
                    resolve({ result });
                });
        });
    }

    static orderPayJsapi(params) {
        return axios.postJson(`${commonUkey}/pay/jsapi`, params);
    }
    static getOrderPayStaus(params) {
        return axios.postJson(`${commonUkey}/getorder`, params);
    }
    static sendMobile(params) {
        return axios.postJson(`${commonUkey}/resend`, params);
    }

    /**
     * 获取手绘签名链接的二维码
     *
     * @getGenerateSignUrl
     * @params { orderType, commonOrderCert }
     */
    static getGenerateSignUrl(params) {
        return axios.postJson(`${commonUkey}/generate-sign-url`, params);
    }
    /**
     * 获取移动签章激活二维码
     */
    static getActivateSeal(params) {
        return axios.postJson(`${commonUkey}/activate/seal`, params);
    }

    /**
     * 查询当前登录手机号下所有ukey设备
     */
    static getAccountUkey() {
        return axios.get('/common/order/ukey/query/devices');
    }

    /**
     * 查询指定设备Id的Ukey 信息
     */
    static queryUkeyInfoByIdDeviceId(deviceId) {
        return axios.get('/common/order/ukey/query/device', {
            deviceId,
        });
    }

    /**
     * 获取ca机构映射
     */
    static getCaMap() {
        return axios.postJson(`${commonUkey}/getca`, {
            customerId: null,
        });
    }

    /**
     *  申请Ukey证书
     */
    static applyUkeyCert(params) {
        return axios.postJson(`${commonUkey}/ukey/apply-cert`, params);
    }

    /**
     * 下载证书
     */
    static downloadCertInfo(params) {
        return axios.postJson(`${commonUkey}/ukey/download-cert`, params);
    }

    /**
     * 获取要写入ukey的印章图片
     * @returns
     */
    static getWriteUkeySealImg(params) {
        return axios.get(`${commonUkey}/ukey/seal`, params);
    }

    /**
     * 写入成功
     */
    static writeSuccess(orderId, orderCertId) {
        return axios.get(`${commonUkey}/ukey/write-success`, { orderId, orderCertId });
    }

    /**
     *  修改 需要章面字段
     *  id needSeal
     */
    static changeNeedSeal(params) {
        return axios.postJson(`${commonUkey}/cert/update`, params);
    }

    /**
     * 获取当前用户支持的 服务
     */
    static getSupportService(params) {
        return axios.postJson(`${commonUkey}/support-services`, params);
    }
}
