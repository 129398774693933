/**
 * @param date
 * @param fmt
 */
export function formatDate(date, fmt) {
    //*  `${date.getFullYear()}`这样转字符串
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = `${o[k]}`;
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : `00${str}`.substr(str.length));
        }
    }
    return fmt;
} //时间格式化
/**
 * @param el
 * @param cls
 */
export function addClass(el, cls) {
    if (!el) return;
    let curClass = el.className;
    let classes = (cls || '').split(' ');

    for (let i = 0, len = classes.length; i < len; i++) {
        let clsName = classes[i];
        if (!clsName) continue;

        if (el.classList) {
            el.classList.add(clsName);
        } else {
            if (!hasClass(el, clsName)) {
                curClass += ` ${clsName}`;
            }
        }
    }
    if (!el.classList) {
        el.className = curClass;
    }
}

/**
 * @param el
 * @param cls
 */
export function removeClass(el, cls) {
    if (!el || !cls) return;
    let curClass = ` ${el.className} `;
    let classes = cls.split(' ');

    for (let i = 0, len = classes.length; i < len; i++) {
        let clsName = classes[i];
        if (!clsName) continue;

        if (el.classList) {
            el.classList.remove(clsName);
        } else {
            if (hasClass(el, clsName)) {
                curClass = curClass.replace(` ${clsName} `, ' ');
            }
        }
    }
    if (!el.classList) {
        el.className = trim(curClass);
    }
}

/**
 *
 */
export function isWeChatBrowser() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeChat = /micromessenger/i.test(ua);
    return isWeChat;
}
/**
 *
 */
export function isDingDingBrowser() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeChat = /dingtalk/i.test(ua);
    return isWeChat;
}

/**
 * 序列化url
 *
 * @example serializeUrl({a: 1, b: 2})
 * @param query
 * @param coverSameParam
 * @param urlString
 * @returns {window.location.href}?a=1&b=2
 * @param {*} query：序列化参数对象 coverSameParam：若当前url存在相同参数，是否覆盖，默认不覆盖
 */
export function serializeUrl(query = {}, coverSameParam = false, urlString) {
    const url = urlString ?? decodeURIComponent(window.location.href);
    const queryReg = /\?{1}[A-Za-z0-9\u4e00-\u9fa5_\.\+\-\/\?=&:%]*/g;
    let queryStr = '',
        queryArr = [];
    if (url.match(queryReg)) {
        let arr = url.match(queryReg)?.[0].split('?')[1];
        queryArr = arr?.split('&') ?? [];
    }
    Object.keys(query).forEach((q) => {
        let index = 0;
        let queryItem = queryArr.find((item, i) => {
            let paramKey = item.split('=')[0];
            index = i;
            return paramKey === q;
        });
        // 当前链接已存在相同参数，根据配置是否删除
        if (!!queryItem && coverSameParam) {
            queryArr.splice(index, 1);
        }
        if (!queryItem || coverSameParam) {
            queryArr.push(`${q}=${query[q]}`);
        }
    });
    queryStr = `?${queryArr.join('&')}`;
    return `${url.split('?')[0]}${queryStr}`;
}

/**
 * 获取指定url-search转化后的键值对
 *
 * 若某一项没有值，则value为空字符串
 *
 * 例如:a=&b=other，则转化后的对象为{ a: '', b: 'other' }
 *
 * @param search 需要转化的url-serach，若不传入则默认当前
 */
export const getSearchParamPairs = (search = location.search) => {
    return Object.fromEntries(
        search
            .replace(/^\?/, '')
            .split('&')
            .map((item) => item.split('='))
            .map((item) => [item[0], item[1] ?? ''])
    );
};
