import http from './http';
import Vue from 'vue';

Vue.prototype.$qHttp = {
    get: http.get,
    getImgSrc: http.getImgSrc,
    getFileBlob: http.getFileBlob,
    postForm: http.postForm,
    postMulti: http.postMulti,
    postJson: http.postJson,
    setOptions: http.setOptions,
};
