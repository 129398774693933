import { Order } from './order/order';
import { Seal } from './seal/seal';
import { Captcha } from './captcha/captcha';
import { Setting } from './setting/setting';
import { Renewal } from './renewal/index';

export class Apis {
    static order = Order;
    static seal = Seal;
    static captcha = Captcha;
    static setting = Setting;
    static renewal = Renewal;
}
