/**
 * global-shared
 * 全局申明及导入：共享资源
 *
 * 此文件包括：
 * - Vue全局设置
 * - Vue资源（包括vue公共组件、公共过滤器、缓动样式、挂载于vue原型链上的工具函数等）
 */

import Vue from 'vue';
import * as downloadjs from 'downloadjs';

//  -------------- Vue全局设置 --------------------------------
Vue.config.productionTip = false;

// apis
import { Apis } from '@/api';
Vue.prototype.$_apis = Apis;

// --------------- Vue资源 -----------------------------------
// API
const configApi = API;
Vue.prototype.configApi = configApi;

// utils
import { Utils, Constant } from '@/tools/index.js';
Vue.prototype.$_utils = Utils;
Vue.prototype.$_const = Constant;

// 过滤器
import * as Filters from '@/filters/index.js';
Object.keys(Filters).forEach((key) => {
    Vue.filter(key, Filters[key]);
});

import { Toast } from 'vant';
Vue.prototype.$loading = Toast.loading;
Vue.prototype.$toast = Toast;

Vue.prototype.$noSupportUpload = function (e) {
    let file = e.target.files[0];
    return !file.type;
};

Vue.prototype.downloadByRes = async function (res) {
    console.log(res, res.headers);
    let fileName = res.headers['content-disposition'].split('=');
    fileName = fileName[fileName.length - 1];
    fileName = fileName.replace(/(utf-8|\')/gi, '').replace(/\+/g, ' ');
    fileName = decodeURIComponent(fileName);
    downloadjs(res.data, fileName);
};
