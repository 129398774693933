export function aesEncrypt(param) {
    var iv = '5f6db7ec8325a5e4';
    var key = '5f6db7ec8325a5e4';

    var c = forge.cipher.createCipher('AES-ECB', key);
    c.start({ iv });
    c.update(forge.util.createBuffer(param, 'utf8'));
    c.finish();
    return forge.util.encode64(c.output.getBytes());
}
